/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";

require('./bootstrap');
window.Vue = require('vue');

Vue.component('modules-component', require('./components/Modules.vue').default);
Vue.component('module-component', require('./components/Module.vue').default);
Vue.component('keyform-component', require('./components/Keyform.vue').default);
Vue.component('scoregroup-component', require('./components/ScoreGroup.vue').default);
Vue.component('scoretext-component', require('./components/ScoreText.vue').default);
Vue.component('competences-component', require('./components/Competences.vue').default);
Vue.component('text-edit', require('./components/TextEdit.vue').default);
Vue.component('edit-mode', require('./components/EditMode.vue').default);
Vue.component('dropdown-edit', require('./components/DropdownEdit.vue').default);
Vue.component('textarea-edit', require('./components/TextAreaEdit.vue').default);

const app = new Vue({
    el: '#app'
});
